
import { defineComponent, ref, onMounted } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { Ticket } from '@/models/Order';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    async setup(props) {
        const tickets = ref<Ticket[]>([]);
        const loaded = ref(false);
        function openMap(utl: string) {
            window.open(utl, '_blank');
        }
        onMounted(async () => {
            const response = await api.getOrderTicket(props.id);
            console.log(response);

            if (response.error || response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            tickets.value = response.data?.tickets || [];
            loaded.value = true;
        });
        return { loaded, tickets, openMap };
    }
});
